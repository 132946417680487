<template>
<div>
    <form>
        <div class="form-group">
            <vs-input v-validate="'required|email|min:3'" data-vv-validate-on="blur" name="email" icon-no-border icon="icon icon-user" icon-pack="feather" label="Email" v-model="email" class="w-full" />
            <span class="text-danger text-sm">{{
          errors.first("email")
        }}</span>

        </div>
        <div class="form-group">
            <vs-input data-vv-validate-on="blur" v-validate="'required|min:6|max:30'" type="password" name="password" icon-no-border icon="icon icon-lock" icon-pack="feather" label="Password" v-model="password" class="w-full mt-6" />
            <span class="text-danger text-sm">{{
          errors.first("password")
        }}</span>
        </div>

        <div class="form-group">
            <div class="d-flex justify-content-between">
              <!--  <router-link to="/forgot-password">Forgot Password?</router-link> -->
                <a href="/forgot-password" class="nav-link"  >Forgot Password?</a> 
            </div>
        </div>

        <div class="form-group">
            <button type="button" :disabled="!validateForm" @click="login" class="btn btn-lg btn-primary btn-block">
                Log In
            </button>
        </div>

        <div class="form-group">
            <p class="text-center">
                Don't have an account?
                <a href="javascript:void(0);" @click="showSignup" data-toggle="modal" data-target="#signupModal">Create Account</a>
            </p>
        </div>
    </form>
    <div class="text-center mt-5 google-fb-sign ">

        <!-- Web -->
        <!-- <img src="@/assets/images/pages/G-72.png" alt="" class="cursor-pointer" @click="loginWithGoogle"> -->
        <!-- <img src="@/assets/images/pages/FB.png" alt="" class="cursor-pointer ml-4" @click="loginWithFacebook"> -->
        <img src="@/assets/images/pages/btn_google_signin_dark_pressed_web@2x.png" height="52" alt="" class="cursor-pointer m-auto" @click="loginWithGoogle">
        <!-- <img src="@/assets/images/pages/btn_google_signin_light_normal_web@2x.png" height="46" alt="" class="cursor-pointer m-auto mt-2"> -->

        <!-- Mobile -->
        <!-- <img src="@/assets/images/pages/btn_google_dark_normal_ios.png" alt="" class="cursor-pointer md:hidden lg:hidden m-auto" @click="loginWithGoogle">
      <img src="@/assets/images/pages/btn_apple_masked.png" width="43" alt="" class="cursor-pointer md:hidden lg:hidden ml-2"> -->
    </div>

    <div class="text-center google-fb-sign mt-2">
        <img v-if='!isShow' src="@/assets/images/pages/apple-signin-btn.png" height="46" alt="" class="cursor-pointer" @click="loginWithApple">
    </div>

</div>
</template>

<script>
import {
    Hub,
    Auth
} from 'aws-amplify'

export default {
    name: 'LoginStudent',
    data() {
        return {
            email: '',
            password: '',
            isShow: false
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any() && this.email !== '' && this.password !== ''
        },
        validateRegisterForm() {
            return !this.errors.any() && this.displayName !== '' && this.registeration_email !== '' && this.registeration_password !== '' && this.confirm_password !== '' && this.isTermsConditionAccepted === true
        }
    },
    created() {
        // oauth login
        this.checkConnection(),
            Hub.listen("auth", ({
                payload: {
                    event,
                    data
                }
            }) => {
                this.$vs.loading()
                switch (event) {
                    case "signIn":
                        if (data.username.includes('Facebook') || data.username.includes('SignInWithApple') || data.username.includes('Google')) {
                            this.$store.dispatch('auth/finishOAuthSignIn', this.$router)

                        }
                        break;
                    case "signOut":
                        // this.$store.dispatch('auth/OAuthSignOut', this.$router)
                        break;
                }
            });
    },
    methods: {
        checkConnection() {
            if (navigator.userAgent.match(/android/i)) {

                this.isShow = true
                console.log(this.isShow)
            } else {
                this.isShow = false
                console.log(this.isShow)
            }
        },
        showSignup() {
            this.$bvModal.show('signupModal')
        },
        login() {
            this.$vs.loading()
            const payload = {
                userDetails: {
                    email: this.email.toLowerCase(),
                    password: this.password
                },
                notify: this.$vs.notify,
                closeAnimation: this.$vs.loading.close,
                router: this.$router
            }
            this.$store.dispatch('auth/login', payload).then(() => this.$vs.loading.close())
        },
        loginWithFacebook() {
            this.$vs.loading()
            this.$store.dispatch('auth/loginWithFacebook')
        },
        loginWithApple() {
            this.$vs.loading()
            this.$store.dispatch('auth/loginWithApple')
        },
        loginWithGoogle() {
            this.$vs.loading()
            this.$store.dispatch('auth/loginWithGoogle')
        }
    }
}
</script>

<style scoped>

</style>
