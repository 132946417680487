<template>
  <div class="">
    <div class="main masthead position-relative">
      <div class="masthead-overlay"></div>

      <div>
        <b-navbar class="navbar-transparent fixed-top-sm" type="" toggleable="lg" fixed="top">
          <b-container>
            <b-navbar-brand to="/login" class="logo">
              <img src="@/assets/images/pages/MyPals_Logo-01.svg" height="44" width="auto" class="mr-3" alt="MyPals" />
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          </b-container>
        </b-navbar>
      </div>

      <div class="height-100">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <div class="masthead-cont">
                <h1 class="">
                  Create Memes, Share with Friends & Have Fun!
                </h1>
              </div>
              <div class="masthead-cont hidden-sm">
                <h3 style="color: white;">
                  Turn Your Ideas into Hilarious Memes Using Our AI Tools
                </h3>
              </div>
              <div class="prompt-container" v-if="apiCallsCount < 4">
                <input v-model="input" type="text" placeholder="Enter your meme idea..." class="form-control mb-3" />
                <button @click="generateImages" class="btn btn-primary" :disabled="isLoading">
                  {{ isLoading ? 'Generating Memes...' : 'Generate Meme' }}
                </button>
              </div>
              <div v-else>
                <p class="text-warning">Please log in to continue creating memes.</p>
              </div>
              <div v-if='isLoading'>
                <div class="loader-nft">
                  <div class="inner one"></div>
                  <div class="inner two"></div>
                  <div class="inner three"></div>
                </div>
              </div>
              <div v-if="prevImages.length" class="image-results mt-4">
                <h5 class="text-white">Generated Memes:</h5>
                <div class="d-flex flex-wrap">
                  <div v-for="(image, index) in prevImages" :key="index" class="m-2">
                    <img :src="image.url" alt="Generated Meme" class="generated-image" @click="openModal(image.url)" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-5 col-md-6 offset-lg-1 login-col">
              <div class="login-cont">
                <div class="login-head text-center">
                  <h2>Welcome to MyPals</h2>
                  <p>Please log in to your account</p>
                </div>
                <LoginStudent />
              </div>
            </div>
          </div>
        </div>
      </div>
        
      <footer class="footer ">
        <div class="container">
          <div class="row text-center">
            <div class="col-lg-12 col-md-12 copyright">
              <span class="text-small">© Copyright 2024. MyPals All Rights Reserved.</span>
              <span class="text-small ml-2">
                <a href="/legal">Our Privacy Policy</a>
              </span>
            </div>
          </div>
        </div>
      </footer>
      <RegisterModal />

      <b-modal v-model="isModalOpen" size="med" hide-footer hide-header :style="{ padding: '0', margin: '0' }" class="custom-modal">
  <img :src="modalImageUrl" alt="Generated Meme" class="img-fluid" :style="{ padding: '0', margin: '0', border: 'none' }"/>
</b-modal>
    </div>
  </div>
</template>

<script>
import RegisterModal from './RegisterModal'
import LoginStudent from './LoginStudent'

export default {
  name: 'Login',
  data() {
    return {
      input: '',
      prevImages: [],
      isLoading: false,
      isModalOpen: false,
      modalImageUrl: '',
      apiCallsCount: 0
    }
  },
  components: {
    RegisterModal,
    LoginStudent
  },
  created() {
    this.appDownload();
  },
  methods: {
    async generateImages() {
      if(this.apiCallsCount >= 4) return;
      this.isLoading = true;
      this.prevImages = []; // Clear previous images
      const userPrompt = this.input;

      try {
        const eventSource = new EventSource(`https://generate.briks.ai/generate-meme?userPrompt=${userPrompt}`);

        eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.success && data.memeFile) {
            const newImage = { url: `https://generate.briks.ai/${data.memeFile}` };
            this.prevImages.push(newImage);
          } else if (data.message === "Process complete") {
            console.log("All memes generated:", data.memeFiles);
            this.isLoading = false;
            this.apiCallsCount++;
            if (this.prevImages.length === 0) {
              console.error("No images were generated.");
            }
          } else {
            console.error("Error generating meme:", data.error);
            this.isLoading = false;
          }
        };

        eventSource.onerror = (error) => {
          console.error("EventSource encountered an error:", error);
          this.isLoading = false;
          eventSource.close();
        };

      } catch (error) {
        console.error("Error during image generation request:", error);
        this.isLoading = false;
      }
    },
    openModal(imageUrl) {
      this.modalImageUrl = imageUrl;
      this.isModalOpen = true;
    },
    appDownload() {
      if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))) {
        this.downloadApp = true
      }
      if (navigator.userAgent.match(/android/i)) {
        this.downloadApp = true
      }
    },
  }
}
</script>
<style lang="scss" scoped>

html, body {
  overflow: hidden; /* Prevent scrolling */
  width: 100%;
  height: 100%;
}

.main {
  overflow: hidden; /* Prevent scrolling in main container */
  width: 100%;
  height: 100%;
}

#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}

body {
  font-family: "Open Sans", sans-serif !important;
  color: #626262;
  font-size: 16px !important;
}

p {
  font-size: 16px !important;
  margin-bottom: 1rem !important;
}

span {
  font-size: 16px !important;
}

.text-small {
  font-size: 14px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #515365;
  font-weight: 600;
}

h4,
h6 {
  margin-bottom: 0.5rem;
}

.masthead-text {
  color: #fff;
  padding-top: 40px;
  font-size: 21px !important;
}

.navbar-dark .navbar-nav .nav-link {
  font-size: 16px !important;
}

.vs-input--input,
.vs-select--input {
  border-radius: 0 !important;
}

.vs-input--label {
  font-size: 14px !important;
}

.modal-body {
  padding: 0;
  margin: 0;
}
.custom-modal .modal-body {
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 600px) {

  
  .logo-title {
    font-size: 22px;
  }

  .navbar-transparent .navbar-nav {
    background-color: #dc123e !important;
  }

  .navbar-transparent .navbar-nav .nav-link {
    border-bottom: 1px solid #fff;
    padding-left: 10px;
  }

  .navbar-transparent .navbar-nav .mr-2 {
    margin-right: 0px !important;
  }

  .session-card,
  .session-notify {
    display: none !important;
  }

  .height-100 {
    // min-height: 100% !important;
    // height: 100% !important;
  }

  [class*='height-']:not(.align-items-initial) {
    // align-items: unset !important;
  }

  [class*='height-'] {
    // display: unset !important;
  }

  .login-col {
    z-index: 1;
    width: 100%;
  }

  .login-cont {
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px 20px;
  }

  .prompt-container {
    display: none;
  }

  .masthead-cont.hidden-sm {
    display: none;
  }

  .masthead-cont,
  .prompt-container,
  .image-results {
    width: 100%;
    text-align: center;
  }
}

.prompt-container {
  margin-top: 20px;
  .form-control {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  .btn-primary {
    padding: 10px 20px;
  }
}

.image-results {
  .generated-image {
    max-width: 100px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

.custom-modal .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.custom-modal .modal-body {
  padding: 0;
  margin: 0;
}

.custom-modal .modal-dialog {
  margin: 0;
  padding: 0;
}

.custom-modal .modal {
  padding: 0;
}
</style>
